<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/factors/${$route.params.id}`" exact exact-active-class="active">Factor</b-nav-item>
            <b-nav-item :to="`/factors/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes</b-nav-item>
            <b-nav-item :to="`/factors/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
        <b-button class="ml-2" variant="primary" v-on:click="$emit('show', 'set-filter')">Filter<b-badge class="ml-2" variant="warning">NEW</b-badge></b-button>
    </b-card-header>
    <b-card-header class="text-muted bg-light">
        <small>Events are currently processed every 15 minutes.</small>
    </b-card-header>
    <b-card-body v-if="events">
        <b-table id="table" outlined hover :items="events.items" :fields="fields" primary-key="id" sort-by="time" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => item._showDetails = (item.reason || (item.detail && item.detail !== '{}')) ? !item._showDetails : false" class="mb-0" show-empty empty-text="No events match your filter. It can take up to 15 minutes for events to show up." empty-filtered-text="No events match your search. If more events are available you can load them by clicking 'Load More' below.">
            <template #cell(result)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ results[data.value] }}</b-button>
            </template>
            <template #row-details="data">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-body v-if="data.item.reason">
                        <b-row>
                            <b-col xl="6" xxl="4">
                                <b-form-group label="Reason" label-align-sm="right" label-cols-sm="3">
                                    <b-form-input v-model="data.item.reason" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-body v-if="data.item.detail && data.item.detail !== '{}'">
                        <b-card class="shadow rounded bg-white" no-body fluid>
                            <b-card-header class="bg-light d-flex">
                                <b-img src="/img/icons/detail.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                <h5 class="text-secondary mb-0 mr-2">Detail</h5>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col xl="12">
                                        <b-form-group>
                                            <b-form-textarea :value="JSON.parse(data.item.detail)" rows="10" readonly></b-form-textarea>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-footer class="text-muted bg-light" v-if="data.item.action.startsWith('update')">
                                <small>To understand the format please see the <b-link href="https://www.npmjs.com/package/json-diff" target="_blank">json-diff</b-link> documentation.</small>
                            </b-card-footer>
                        </b-card>
                    </b-card-body>
                </b-card>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'events')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="events" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? events.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="events?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'events')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="events?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ events.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
 const FIELDS = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        formatter: value => TYPES[value]
    },
    {
        key: 'action',
        label: 'Action',
        sortable: true
    },
    {
        key: 'result',
        label: 'Result',
        sortable: true
    }
];
const RESULTS = {
    'PENDING': 'Pending',
    'SUCCESS': 'Success',
    'FAILED': 'Failed'
};
const TYPES = {
    'API': 'API',
    'DATA': 'Data',
    'DATABASE': 'Database',
    'COMMUNICATION': 'Communication',
    'AUTHENTICATION': 'Authentication',
    'AUTHORIZATION': 'Authorization'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'EnrollmentEvents',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        events: Array
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // RESULTS
            results: RESULTS,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.events) {
                this.$emit('load', 'events', false);
            }
        }
    }
}
</script>
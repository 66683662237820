<template>
    <b-card-group deck>

        <!-- CUSTOMER -->
        <b-card v-if="$store.state.customer && !$store.state.customer.email" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/warning.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Missing Email</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Your account is missing a verified email. Without a verified email you won't be able to create tenants.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" variant="success" v-on:click="$emit('show', 'create-enrollment', email_factor)">Verify</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
            </b-card-footer>
        </b-card>

        <!-- PAYMENT -->
        <b-card v-if="$store.state.subscriptions?.items.some(subscription => subscription.plan === 'payg') && $store.state.payment && !$store.state.payment.id" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/warning.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Missing Payment</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Your account is missing a payment method. Without a payment method you'll need to stay within your quota.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" to="/payment" variant="outline-primary">Resolve</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
            </b-card-footer>
        </b-card>

        <!-- TENANT -->
        <b-card v-if="$store.state.tenants && !$store.state.tenants.items.length" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/play.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Getting Started</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Get started by creating a tenant. Tenants allow you to setup login, access and realise your projects.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button v-if="!$store.state.customer?.email" class="ml-auto" variant="outline-danger" v-on:click="$emit('show', 'create-enrollment', email_factor)">Missing Email</b-button>
                <b-button v-else class="ml-auto" v-on:click="$emit('show', 'create-tenant', { subscription: $store.state.subscriptions?.items[0].id, config: { interfaces: { color: '#3C78D8' } } })" variant="success" :disabled="!$store.state.subscriptions?.items.length">Create</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#4304e3f2-3499-4444-a79b-4543bdcd8639" target="_blank">Management API</a></small>
            </b-card-footer>
        </b-card>

        <!-- METRICS (MAT) -->
        <b-card v-for="subscription in $store.state.subscriptions?.items" v-bind:key="subscription.id" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/tenant.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly Active Tenants</span>
                <b-badge class="ml-2" variant="danger">METRIC</b-badge>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>For {{ subscription.id }}</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is your quota consumption.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1 :class="`text-${getUsage(subscription)[0].variant}`">{{ Math.round(getUsage(subscription)[0].percent) }}%</h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ getUpdated(subscription).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- METRICS (MAA) -->
        <b-card v-for="subscription in $store.state.subscriptions?.items" v-bind:key="subscription.id" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/account.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly Active Accounts</span>
                <b-badge class="ml-2" variant="danger">METRIC</b-badge>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>For {{ subscription.id }}</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is your quota consumption.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1 :class="`text-${getUsage(subscription)[1].variant}`">{{ Math.round(getUsage(subscription)[1].percent) }}%</h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ getUpdated(subscription).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- METRICS (MAC) -->
        <b-card v-for="subscription in $store.state.subscriptions?.items" v-bind:key="subscription.id" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly API Calls</span>
                <b-badge class="ml-2" variant="danger">METRIC</b-badge>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>For {{ subscription.id }}</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is your quota consumption.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1 :class="`text-${getUsage(subscription)[2].variant}`">{{ Math.round(getUsage(subscription)[2].percent) }}%</h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ getUpdated(subscription).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- SPEND -->
        <b-card v-if="$store.state.invoices?.items" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/currency.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Current Spend</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is your current spend. As long as you stay within your quota you won't be charged. We notify you if nearing.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1 :class="`text-${getSpend().variant}`">€ {{ getSpend().total }}</h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date(new Date().setHours(1,0,0)).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- RECOVERY -->
        <b-card v-if="$store.state.enrollments && $store.state.enrollments.items.length < 2 && $store.state.factors?.items.length" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/account-locked.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Account Recovery</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        We recommend adding more factors to your account, as to give you some options to recover it in case of issue.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" v-on:click="$emit('show', 'create-enrollment', {})" variant="primary">Create</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#1fba2eb8-4cb5-4557-b7cb-880d8a7432c3" target="_blank">Authentication API</a></small>
            </b-card-footer>
        </b-card>

        <!-- SOLID -->
        <b-card v-if="root && $store.state.account_id" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/share.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Data Sharing</span>
                <b-badge class="ml-2" variant="solid">Solid</b-badge>
                <b-badge class="ml-2" variant="warning">NEW</b-badge>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is the Web ID of your account. It gives linked data about your account (using JSON-LD, Turtle or RDF/XML).
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body>
                <b-input-group>
                    <b-form-input :value="`https://${$store.state.tenant_id}.api${$store.state.domain}/accounts/${$store.state.account_id}#me`" readonly></b-form-input>
                    <b-input-group-append>
                        <b-button :href="`https://${$store.state.tenant_id}.api${$store.state.domain}/accounts/${$store.state.account_id}#me`" target="_blank" variant="outline-primary">Open</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://solidproject.org/" target="_blank">Solid</a></small>
            </b-card-footer>
        </b-card>

        <!-- DOCUMENTATION -->
        <b-card v-if="root" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/documentation.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our Docs</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our product documentation to better understand certain concepts as well as how to realise all your projects.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://docs.quasr.io" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://gitbook.com" target="_blank">GitBook</a></small>
            </b-card-footer>
        </b-card>

        <!-- API -->
        <b-card v-if="root" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our API</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our API collection. We have an Authentication API which is REST, and a Management API which is GraphQL.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://documenter.getpostman.com/view/18129777/UVXgMHEv" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://postman.com" target="_blank">Postman</a></small>
            </b-card-footer>
        </b-card>

        <!-- SCHEMA -->
        <b-card v-if="root" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/schema.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our Schema</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our GraphQL schema visually. Learn what concepts are available and how they're connected to each other.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://quasr-io.github.io/graphql/" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://github.com/graphql-kit/graphql-voyager" target="_blank">GraphQL Voyager</a> & <a href="https://github.com/" target="_blank">GitHub</a></small>
            </b-card-footer>
        </b-card>

        <!-- COMMUNITY -->
        <b-card v-if="root" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/community.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Join Our Community</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Join our community for free support, announcements, release notes, peer interaction or to provide us feedback.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://discord.com/channels/895325971278856292/895413575491936257" target="_blank" variant="outline-primary">Join</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://discord.com" target="_blank">Discord</a></small>
            </b-card-footer>
        </b-card>

    </b-card-group>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const EMAIL_FACTOR = 'fd26d514-7c72-4ba3-a60f-dccc475d971e';
const EMAIL_REGEX = '^(([^<>()[ \\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'Dashboard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'next' ],

    /**
     * DATA
     */
    data() {
        return {
            // EMAIL
            email_factor: {
                id: EMAIL_FACTOR,
                label: 'Email One-Time Password',
                subtype: 'otp',
                config: { regex: EMAIL_REGEX }
            }
        }
    },

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        root: Boolean
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (this.root) {
                    if (!this.$store.state.customer) {
                        this.$emit('load', 'customer');
                    }
                    if (!this.$store.state.payment) {
                        this.$emit('load', 'payment');
                    }
                    if (!this.$store.state.subscriptions || !this.$store.state.subscriptions.items.length) {
                        this.$emit('load', 'subscriptions');
                    }
                    if (!this.$store.state.tenants) {
                        this.$emit('load', 'tenants');
                    }
                    if (!this.$store.state.invoices) {
                        this.$emit('load', 'invoices');
                    }
                }
                if (!this.$store.state.enrollments) {
                    this.$emit('load', 'enrollments');
                }
                if (!this.$store.state.factors) {
                    this.$emit('load', 'factors', true); // LOAD ALL
                } else if (this.$store.state.factors.nextToken) {
                    this.$emit('next', 'factors', this.$store.state.factors.nextToken, true); // LOAD ALL
                }
            }
        },

        /**
         * USAGE
         */
        getUsage(subscription) {
            const usage = [
                { name: 'mat', quota: subscription.mat, value: 0, percent: 0, variant: 'success' },
                { name: 'maa', quota: subscription.maa, value: 0, percent: 0, variant: 'success' },
                { name: 'mac', quota: subscription.mac, value: 0, percent: 0, variant: 'success' },
            ];
            if (this.$store.state.tenants?.items) {
                for (const tenant of this.$store.state.tenants.items) {
                    if (tenant.subscription === subscription.id) {
                        if (tenant.metrics.maa > 0) usage[0].value++;
                        usage[1].value += tenant.metrics.maa;
                        usage[2].value += tenant.metrics.mac;
                    }
                }
            }
            usage[2].quota = Math.max(usage[2].quota, usage[1].value * 10);
            for (const metric of usage) {
                metric.percent = (metric.value / metric.quota) * 100;
                if (metric.percent > 100) metric.variant = 'danger';
                else if (metric.percent > 80) metric.variant = 'warning';
            }
            return usage;
        },

        getUpdated(subscription) {
            var last_updated_at;
            if (this.$store.state.tenants?.items) {
                for (const tenant of this.$store.state.tenants.items) {
                    if (tenant.subscription === subscription.id) {
                        const updated_at = new Date(tenant.metrics.updated_at);
                        if (!last_updated_at || updated_at > last_updated_at) {
                            last_updated_at = updated_at;
                        }
                    }
                }
            }
            return last_updated_at || new Date();
        },

        /**
         * SPEND
         */
        getSpend() {
            var spend = { total: 0, variant: 'success' };
            if (this.$store.state.invoices?.items) {
                for (const invoice of this.$store.state.invoices.items) {
                    if (invoice.status === 'draft') {
                        spend.total += invoice.total;
                        if (invoice.total && spend.variant !== 'danger') {
                            spend.variant = 'warning';
                        }
                    } else if (invoice.status === 'open') {
                        spend.total += invoice.total;
                        spend.variant = 'danger';
                    }
                }
            }
            spend.total = (spend.total / 100).toFixed(2);
            return spend;
        }
    }
}
</script>
/**
 * IMPORTS
 */
import { createApp, configureCompat } from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createStore } from 'vuex';

import App from './App.vue';
import Dashboard from './components/Dashboard.vue';
import Account from './components/Account.vue';
import Claims from './components/Claims.vue';
import ClaimCard from './components/ClaimCard.vue';
import Claim from './components/claim/Claim.vue';
import ClaimEnrollments from './components/claim/ClaimEnrollments.vue';
import EnrollmentCard from './components/EnrollmentCard.vue';
import Enrollment from './components/enrollment/Enrollment.vue';
import EnrollmentClaims from './components/enrollment/EnrollmentClaims.vue';
import EnrollmentEvents from './components/enrollment/EnrollmentEvents.vue';
import Enrollments from './components/Enrollments.vue';
import Controls from './components/Controls.vue';
import Consent from './components/Consent.vue';
import Permission from './components/Permission.vue';
import Tokens from './components/Tokens.vue';
import Token from './components/Token.vue';
import Events from './components/Events.vue';
import Event from './components/Event.vue';

import Customer from './components/root/Customer.vue';
import Tenants from './components/root/Tenants.vue';
import Tenant from './components/root/Tenant.vue';
import Subscriptions from './components/root/Subscriptions.vue';
import Invoices from './components/root/Invoices.vue';
import Payment from './components/root/Payment.vue';
import Prices from './components/root/Prices.vue';

import './assets/scss/custom.scss';

/**
 * CONFIGURATION
 */
configureCompat({ MODE: 2 });
const APP = createApp(App);
APP.use(BootstrapVue, {
    breakpoints: [ 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ]
});

/**
 * STORE
 */
const STORE = createStore({
    state: {
        tenant_id: undefined,
        domain: undefined,
        session: undefined,
        account_id: undefined,
        account: undefined,
        customer: undefined,
        payment: undefined,
        tenants: undefined,
        consents: undefined,
        events: undefined,
        enrollments: undefined,
        permissions: undefined,
        subscriptions: undefined,
        invoices: undefined,
        factors: undefined,
        controls: undefined,
        tokens: undefined,
        attributes: undefined,
        claims: undefined
    },
    mutations: {
        tenant_id(state, tenant_id) {
            state.tenant_id = tenant_id;
        },
        domain(state, domain) {
            state.domain = domain;
        },
        session(state, session) {
            state.session = session;
        },
        account_id(state, account_id) {
            state.account_id = account_id;
        },
        account(state, account) {
            state.account = account;
        },
        customer(state, customer) {
            state.customer = customer;
        },
        payment(state, payment) {
            state.payment = payment;
        },
        tenants(state, tenants) {
            state.tenants = tenants;
        },
        push_tenant(state, tenant) {
            state.tenants.items.push(tenant);
        },
        set_tenants_token(state, token) {
            state.tenants.nextToken = token;
        },
        consents(state, consents) {
            state.consents = consents;
        },
        push_consent(state, consent) {
            state.consents.items.push(consent);
        },
        set_consents_token(state, token) {
            state.consents.nextToken = token;
        },
        events(state, events) {
            state.events = events;
        },
        push_event(state, event) {
            state.events.items.push(event);
        },
        set_events_token(state, token) {
            state.events.nextToken = token;
        },
        enrollments(state, enrollments) {
            state.enrollments = enrollments;
        },
        push_enrollment(state, enrollment) {
            state.enrollments.items.push(enrollment);
        },
        set_enrollments_token(state, token) {
            state.enrollments.nextToken = token;
        },
        permissions(state, permissions) {
            state.permissions = permissions;
        },
        push_permission(state, permission) {
            state.permissions.items.push(permission);
        },
        set_permissions_token(state, token) {
            state.permissions.nextToken = token;
        },
        subscriptions(state, subscriptions) {
            state.subscriptions = subscriptions;
        },
        invoices(state, invoices) {
            state.invoices = invoices;
        },
        factors(state, factors) {
            state.factors = factors;
        },
        push_factor(state, factor) {
            state.factors.items.push(factor);
        },
        set_factors_token(state, token) {
            state.factors.nextToken = token;
        },
        controls(state, controls) {
            state.controls = controls;
        },
        push_control(state, control) {
            state.controls.items.push(control);
        },
        set_controls_token(state, token) {
            state.controls.nextToken = token;
        },
        tokens(state, tokens) {
            state.tokens = tokens;
        },
        push_token(state, token) {
            state.tokens.items.push(token);
        },
        set_tokens_token(state, token) {
            state.tokens.nextToken = token;
        },
        attributes(state, attributes) {
            state.attributes = attributes;
        },
        push_attribute(state, attribute) {
            state.attributes.items.push(attribute);
        },
        set_attributes_token(state, token) {
            state.attributes.nextToken = token;
        },
        claims(state, claims) {
            state.claims = claims;
        },
        push_claim(state, claim) {
            state.claims.items.push(claim);
        },
        set_claims_token(state, token) {
            state.claims.nextToken = token;
        }
    }
});
APP.use(STORE);

/**
 * ROUTER
 */
const ROUTER = createRouter({
    history: createWebHistory(),
    routes: [
        // GENERAL
        { path: '/', component: Dashboard },
        { path: '/account', component: Account },
        { path: '/attributes', component: Claims },
        { path: '/attributes/:id', component: ClaimCard, children: [
            { path: '', component: Claim },
            { path: 'factors', component: ClaimEnrollments }
        ]},
        { path: '/factors', component: Enrollments },
        { path: '/factors/:id', component: EnrollmentCard, children: [
            { path: '', component: Enrollment },
            { path: 'attributes', component: EnrollmentClaims },
            { path: 'events', component: EnrollmentEvents }
        ]},
        { path: '/controls', component: Controls },
        { path: '/consents/:id', component: Consent },
        { path: '/permissions/:id', component: Permission },
        { path: '/tokens', component: Tokens },
        { path: '/tokens/:id', component: Token },
        { path: '/events', component: Events },
        { path: '/events/:id', component: Event },
        // ROOT
        { path: '/customer', component: Customer },
        { path: '/tenants', component: Tenants },
        { path: '/tenants/:id', component: Tenant },
        { path: '/subscriptions', component: Subscriptions },
        { path: '/payment', component: Payment },
        { path: '/invoices', component: Invoices },
        { path: '/prices', component: Prices }
    ]
});
APP.use(ROUTER);

/**
 * APPLICATION
 */
APP.mount('#app');

<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tenants.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Tenants</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button v-if="!$store.state.customer?.email" class="ml-2" variant="outline-danger" v-on:click="$emit('show', 'create-enrollment', email_factor)">Missing Email</b-button>
                <b-button v-else class="ml-2" variant="primary" v-on:click="$emit('show', 'create-tenant', { subscription: $store.state.subscriptions?.items[0].id, config: { interfaces: { color: '#3C78D8' } } })" :disabled="!$store.state.subscriptions?.items.length">Create</b-button>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Metrics are currently processed every 5 minutes.</small>
            </b-card-header>
            <b-card-body v-if="$store.state.tenants">
                <b-table id="table" outlined hover :items="$store.state.tenants.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/tenants/${item.id}`)" class="mb-0" show-empty empty-text="You have no tenants. You can create a new tenant by clicking 'Create' above." empty-filtered-text="No tenants match your search. If more tenants are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button :href="`https://${data.item.id}.admin${$store.state.domain}`" target="_blank" variant="primary" size="sm" :disabled="data.item.status !== 'ENABLED'">Admin</b-button>
                        <b-button class="ml-2" variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('show', 'delete-tenant', data.item)">Delete</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="$emit('load', 'tenants')">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.tenants" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.tenants.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.tenants?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'tenants', $store.state.tenants.nextToken)" class="ml-2">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.tenants?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.tenants.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const EMAIL_FACTOR = 'fd26d514-7c72-4ba3-a60f-dccc475d971e';
const EMAIL_REGEX = '^(([^<>()[ \\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'subscription',
        label: 'Subscription',
        sortable: true
    },
    {
        key: 'metrics.maa',
        label: 'MAA',
        sortable: true
    },
    {
        key: 'metrics.mac',
        label: 'MAC',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Tenants',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // TENANT
            tenant: {},
            // EMAIL
            email_factor: {
                id: EMAIL_FACTOR,
                label: 'Email One-Time Password',
                subtype: 'otp',
                config: { regex: EMAIL_REGEX }
            }
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.$store.state.tenants) {
                    this.$emit('load', 'tenants');
                }
                if (!this.$store.state.customer) {
                    this.$emit('load', 'customer');
                }
                if (!this.$store.state.subscriptions || !this.$store.state.subscriptions.items.length) {
                    this.$emit('load', 'subscriptions');
                }
                if (!this.$store.state.customer?.email && !this.$store.state.factors) {
                    this.$emit('load', 'factors', true); // LOAD ALL
                }
            }
        }
    }
}
</script>
<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/controls.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Permission</h3>
            </b-card-header>
            <b-card-body v-if="permission">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="permission.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="permission.label" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="$store.state.controls?.items">
                        <b-form-group label="Control" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="permission.control" :options="$store.state.controls.items" value-field="id" text-field="label" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="permission.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="permission">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="permission.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="permission.created_by" readonly></b-form-input>
                            <b-badge v-if="permission.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="permission.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="permission.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="permission.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="permission.updated_by" readonly></b-form-input>
                            <b-badge v-if="permission.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="permission.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="permission.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" class="ml-2" v-on:click="getPermission()">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="permission?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ permission.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'legal', text: 'Legal' },
    { value: 'scope', text: 'Scope' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Permission',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // PERMISSION
            permission: undefined,
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.permission) {
                    this.getPermission();
                }
                if (!this.$store.state.controls) {
                    this.$emit('load', 'controls', true); // LOAD ALL
                } else if (this.$store.state.controls.nextToken) {
                    this.$emit('next', 'controls', this.$store.state.controls.nextToken, true); // LOAD ALL
                }
            }
        },

        /**
         * PERMISSION
         */
        async getPermission() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getPermission($id: ID!) {
                                getPermission(id: $id) {
                                    id
                                    label
                                    control
                                    status
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.permission = (await response.json()).data.getPermission;
                    // SET DATES
                    this.permission.created_at = this.permission.created_at ? new Date(this.permission.created_at) : undefined;
                    this.permission.updated_at = this.permission.updated_at ? new Date(this.permission.updated_at) : undefined;
                    this.permission.expires_at = this.permission.expires_at ? new Date(this.permission.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.permission.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permission.', 'Permission', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permission.', 'Permission', 'danger');
            }
            this.loading = undefined;
        }
    }
}
</script>
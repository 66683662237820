<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PAYMENT
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/payment.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Payment</h3>
            </b-card-header>
            <div v-if="getPayment()">
                <b-card-body v-if="payment.card">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex">
                            <b-img src="/img/icons/card.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Card</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Number" label-align-sm="right" label-cols-sm="3" :state="validField('number')" invalid-feedback="Please provide a valid card number.">
                                        <b-form-input v-model="payment.card.number" type="number" :state="validField('number')"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is the month and year your card expires." :state="validField('exp_month') && validField('exp_year')" invalid-feedback="Please provide a valid card expiration.">
                                        <b-input-group>
                                            <b-form-select v-model="payment.card.exp_month" :options="months" :state="validField('exp_month')"></b-form-select>
                                            <b-form-input v-model="payment.card.exp_year" type="number" :state="validField('exp_year')"></b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="CVC" label-align-sm="right" label-cols-sm="3" description="This is your Card Verification Code (CVC). You can usually find this 3 or 4-digit code either on the front or back of your card." :state="validField('cvc')" invalid-feedback="Please provide a valid card verification code.">
                                        <b-form-input v-model="payment.card.cvc" type="number" :state="validField('cvc')"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-card-body>
                <b-card-body v-if="payment.details">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex">
                            <b-img src="/img/icons/details.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Details</h5>
                        </b-card-header>
                        <b-card-body class="pb-0">
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Name" label-align-sm="right" label-cols-sm="3" description="This is the name as displayed on the card." :state="validField('name')" invalid-feedback="Please provide a name.">
                                        <b-form-input v-model="payment.details.name" :state="validField('name')"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Email" label-align-sm="right" label-cols-sm="3" description="This is the email we use for invoicing." :state="validField('email')" invalid-feedback="Please provide a valid email.">
                                        <b-form-input v-model="payment.details.email" type="email" :state="validField('email')"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="VAT" label-align-sm="right" label-cols-sm="3" description="This is the VAT number of your business." :state="payment.details.vat ? validField('vat') : null" :invalid-feedback="payment.details.address.country ? 'Please provide a valid VAT number.' : 'Please select a country first.'">
                                        <b-form-input v-model="payment.details.vat" :state="payment.details.vat ? validField('vat') : null"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-body v-if="payment.details.address">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex">
                                    <b-img src="/img/icons/address.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Address</h5>
                                </b-card-header>
                                <b-card-body>
                                    <b-row>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Line 1" label-align-sm="right" label-cols-sm="3" :state="validField('line1')" invalid-feedback="Please provide an address.">
                                                <b-form-input v-model="payment.details.address.line1" :state="validField('line1')"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Line 2" label-align-sm="right" label-cols-sm="3">
                                                <b-form-input v-model="payment.details.address.line2"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Postal Code" label-align-sm="right" label-cols-sm="3" :state="validField('postal_code')" invalid-feedback="Please provide a postal code.">
                                                <b-form-input v-model="payment.details.address.postal_code" :state="validField('postal_code')"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="City" label-align-sm="right" label-cols-sm="3" :state="validField('city')" invalid-feedback="Please provide a city.">
                                                <b-form-input v-model="payment.details.address.city" :state="validField('city')"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="State" label-align-sm="right" label-cols-sm="3">
                                                <b-form-input v-model="payment.details.address.state"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Country" label-align-sm="right" label-cols-sm="3" :state="validField('country')" invalid-feedback="Please select a country.">
                                                <b-form-select v-model="payment.details.address.country" :options="countries" :state="validField('country')"></b-form-select>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>
                        </b-card-body>
                    </b-card>
                </b-card-body>
                <b-card-body class="d-flex">
                    <b-button variant="success" v-on:click="savePayment()" :disabled="!validPayment()">Save</b-button>
                    <b-button variant="outline-danger" class="ml-auto" v-on:click="resetPayment()">Reset</b-button>
                </b-card-body>
            </div>  
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const MONTHS = [
    { value: '01', text: 'January' },
    { value: '02', text: 'February' },
    { value: '03', text: 'March' },
    { value: '04', text: 'April' },
    { value: '05', text: 'May' },
    { value: '06', text: 'June' },
    { value: '07', text: 'July' },
    { value: '08', text: 'August' },
    { value: '09', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' }
];
const COUNTRIES = [
    { value: 'AD', text: 'Andorra' },
    { value: 'AR', text: 'Argentina' },
    { value: 'AU', text: 'Australia' },
    { value: 'AT', text: 'Austria' },
    { value: 'BE', text: 'Belgium' },
    { value: 'BO', text: 'Bolivia' },
    { value: 'BR', text: 'Brazil' },
    { value: 'BG', text: 'Bulgaria' },
    { value: 'CA', text: 'Canada' },
    { value: 'CL', text: 'Chile' },
    { value: 'CN', text: 'China' },
    { value: 'CO', text: 'Colombia' },
    { value: 'CR', text: 'Costa Rica' },
    { value: 'HR', text: 'Croatia' },
    { value: 'CY', text: 'Cyprus' },
    { value: 'CZ', text: 'Czech Republic' },
    { value: 'DK', text: 'Denmark' },
    { value: 'DO', text: 'Dominican Republic' },
    { value: 'EC', text: 'Ecuador' },
    { value: 'EG', text: 'Egypt' },
    { value: 'SV', text: 'El Salvador' },
    { value: 'EE', text: 'Estonia' },
    { value: 'FI', text: 'Finland' },
    { value: 'FR', text: 'France' },
    { value: 'GE', text: 'Georgia' },
    { value: 'DE', text: 'Germany' },
    { value: 'GR', text: 'Greece' },
    { value: 'HK', text: 'Hong Kong SAR China' },
    { value: 'HU', text: 'Hungary' },
    { value: 'IS', text: 'Iceland' },
    { value: 'IN', text: 'India' },
    { value: 'ID', text: 'Indonesia' },
    { value: 'IE', text: 'Ireland' },
    { value: 'IL', text: 'Israel' },
    { value: 'IT', text: 'Italy' },
    { value: 'JP', text: 'Japan' },
    { value: 'KE', text: 'Kenia' },
    { value: 'LV', text: 'Latvia' },
    { value: 'LI', text: 'Liechtenstein' },
    { value: 'LT', text: 'Lithuania' },
    { value: 'LU', text: 'Luxembourg' },
    { value: 'MA', text: 'Malaysia' },
    { value: 'MT', text: 'Malta' },
    { value: 'MX', text: 'Mexico' },
    { value: 'NL', text: 'Netherlands' },
    { value: 'NZ', text: 'New Zealand' },
    { value: 'NO', text: 'Norway' },
    { value: 'PY', text: 'Paraguay' },
    { value: 'PE', text: 'Peru' },
    { value: 'PH', text: 'Philippines' },
    { value: 'PL', text: 'Poland' },
    { value: 'PT', text: 'Portugal' },
    { value: 'RO', text: 'Romania' },
    // { value: 'RU', text: 'Russia' },
    { value: 'RS', text: 'Serbia' },
    { value: 'SA', text: 'Saudi Arabia' },
    { value: 'SG', text: 'Singapore' },
    { value: 'SK', text: 'Slovakia' },
    { value: 'SI', text: 'Slovenia' },
    { value: 'ZA', text: 'South Africa' },
    { value: 'KR', text: 'South Korea' },
    { value: 'ES', text: 'Spain' },
    { value: 'SE', text: 'Sweden' },
    { value: 'CH', text: 'Switzerland' },
    { value: 'TW', text: 'Taiwan' },
    { value: 'TH', text: 'Thailand' },
    { value: 'TT', text: 'Trinidad & Tobago' },
    { value: 'UA', text: 'Ukraine' },
    { value: 'AE', text: 'United Arab Emirates' },
    { value: 'GB', text: 'United Kingdom' },
    { value: 'US', text: 'United States' },
    { value: 'UY', text: 'Uruguay' },
    { value: 'VE', text: 'Venezuela' },
    { value: 'VN', text: 'Vietnam' }
];
const CARD_NUMBER_REGEX = new RegExp('[0-9]{14,19}');
const CARD_CVC_REGEX = new RegExp('[0-9]{3,4}');
const EMAIL_REGEX = new RegExp('^(([^<>()[ \\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
const VAT_REGEX = {
    'AT': [ new RegExp('^(AT)?U[0-9]{8}$') ], // AUSTRIA
    'BE': [ new RegExp('^(BE)?0[0-9]{9}$') ], // BELGIUM
    'BG': [ new RegExp('^(BG)?[0-9]{9,10}$') ], // BULGARIA
    'HR': [ new RegExp('^(HR)?[0-9]{11}$') ], // CROATIA
    'CY': [ new RegExp('^(CY)?[0-9]{8}[A-Z]$') ], // CYPRUS
    'DZ': [ new RegExp('^(CZ)?[0-9]{8,10}$') ], // CZECH REPUBLIC
    'DE': [ new RegExp('^(DE)?[0-9]{9}$') ], // GERMANY
    'DK': [ new RegExp('^(DK)?[0-9]{8}$') ], // DENMARK
    'EE': [ new RegExp('^(EE)?[0-9]{9}$') ], // ESTONIA
    'GR': [ new RegExp('^(EL)?[0-9]{9}$'), new RegExp('^(GR)?[0-9]{9}$') ], // GREECE
    'ES': [ new RegExp('^ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$') ], // SPAIN
    'FI': [ new RegExp('^(FI)?[0-9]{8}$') ], // FINLAND
    'FR': [ new RegExp('^(FR)?[0-9A-Z]{2}[0-9]{9}$') ], // FRANCE
    'GB': [ new RegExp('^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$') ], // UNITED KINGDOM
    'HU': [ new RegExp('^(HU)?[0-9]{8}$') ], // HUNGARY
    'IE': [ new RegExp('^(IE)?[0-9]{7}[A-Z]{1,2}$'), new RegExp('^(IE)?[0-9][A-Z][0-9]{5}[A-Z]$') ], // IRELAND
    'IT': [ new RegExp('^(IT)?[0-9]{11}$') ], // ITALY
    'LT': [ new RegExp('^(LT)?([0-9]{9}|[0-9]{12})$') ], // LITHUANIA
    'LU': [ new RegExp('^(LU)?[0-9]{8}$') ], // LUXEMBOURG
    'LV': [ new RegExp('^(LV)?[0-9]{11}$') ], // LATVIA
    'MT': [ new RegExp('^(MT)?[0-9]{8}$') ], // MALTA
    'NL': [ new RegExp('^(NL)?[0-9]{9}B[0-9]{2}$') ], // NETHERLANDS
    'PL': [ new RegExp('^(PL)?[0-9]{10}$') ], // POLAND
    'PT': [ new RegExp('^(PT)?[0-9]{9}$') ], // PORTUGAL
    'RO': [ new RegExp('^(RO)?[0-9]{2,10}$') ], // ROMANIA
    'SE': [ new RegExp('^(SE)?[0-9]{12}$') ], // SWEDEN
    'SI': [ new RegExp('^(SI)?[0-9]{8}$') ], // SLOVENIA
    'SK': [ new RegExp('^(SK)?[0-9]{10}$') ] // SLOVAKIA
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Payment',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // PAYMENT
            payment: undefined,
            // MONTHS
            months: MONTHS,
            // COUNTRIES
            countries: COUNTRIES,
            // LOADING
            loading2: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },
     
    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                this.getPayment();
            }
        },

        /**
         * PAYMENT
         */
        getPayment() {
            if (!this.$store.state.payment) {
                this.$emit('load', 'payment');
                return false;
            } else if (!this.payment) {
                this.resetPayment();
            }
            return true;
        },

        async savePayment() {
            this.loading2 = 'Saving';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/account/payment`, {
                    method: 'POST',
                    body: JSON.stringify(this.payment),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const payment = await response.json();
                    // ADD REFRESH DATE
                    payment.refreshed_at = new Date();
                    this.$store.commit('payment', payment);
                    this.resetPayment();
                    this.$emit('alert', 'Your payment has been updated.', 'Payment', 'success', 5000);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save payment.', 'Payment', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save payment.', 'Payment', 'danger');
            }
            this.loading2 = undefined;
        },

        resetPayment() {
            this.payment = {
                id: this.$store.state.payment.id,
                card: {
                    number: this.$store.state.payment.card.last4 ? `**********${this.$store.state.payment.card.last4}`: undefined,
                    exp_month: this.$store.state.payment.card.exp_month,
                    exp_year: this.$store.state.payment.card.exp_year
                },
                details: {
                    name: this.$store.state.payment.details.name,
                    email: this.$store.state.payment.details.email || this.$store.state.customer?.email,
                    vat: this.$store.state.payment.details.var,
                    address: {
                        line1: this.$store.state.payment.details.address.line1,
                        line2: this.$store.state.payment.details.address.line2,
                        postal_code: this.$store.state.payment.details.address.postal_code,
                        city: this.$store.state.payment.details.address.city,
                        state: this.$store.state.payment.details.address.state,
                        country: this.$store.state.payment.details.address.country
                    }
                }
            };
        },

        /**
         * VALIDATION
         */
        validPayment() {
            if (!this.validField('number')) return false;
            if (!this.validField('exp_month')) return false;
            if (!this.validField('exp_year')) return false;
            if (!this.validField('cvc')) return false;
            if (!this.validField('name')) return false;
            if (!this.validField('email')) return false;
            if (this.payment.details.vat && !this.validField('vat')) return false;
            if (!this.validField('line1')) return false;
            if (!this.validField('postal_code')) return false;
            if (!this.validField('city')) return false;
            if (!this.validField('country')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'number':
                    return CARD_NUMBER_REGEX.test(this.payment.card.number);
                case 'exp_month':
                    return !!this.payment.card.exp_month;
                case 'exp_year':
                    return this.payment.card.exp_year > 2023;
                case 'cvc':
                    return CARD_CVC_REGEX.test(this.payment.card.cvc);
                case 'name':
                    return !!this.payment.details.name;
                case 'email':
                    return EMAIL_REGEX.test(this.payment.details.email);
                case 'vat':
                    if (!this.payment.details.address.country) return false;
                    return VAT_REGEX[this.payment.details.address.country]?.some(regex => regex.test(this.payment.details.vat));
                case 'line1':
                    return !!this.payment.details.address.line1;
                case 'postal_code':
                    return !!this.payment.details.address.postal_code;
                case 'city':
                    return !!this.payment.details.address.city;
                case 'country':
                    return !!this.payment.details.address.country;
                default:
                    return false;
            }
        }
    }
}
</script>
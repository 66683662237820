<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/account.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Account</h3>
            </b-card-header>
            <b-card-body v-if="account" class="pb-0">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="account.id" readonly></b-form-input>
                            <b-badge style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="account.label" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="account.subtype" :options="subtypes" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="account.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="account?.config">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                        <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                    </b-card-header>
                    <b-collapse id="configuration" visible>
                        <b-card-body>
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Internal" label-align-sm="right" label-cols-sm="3" description="This indicates whether your account is considered internal.">
                                        <b-form-checkbox v-model="account.config.internal" switch disabled></b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="account">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="account.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="account.created_by" readonly></b-form-input>
                            <b-badge v-if="account.created_by === account.id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="account.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="account.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="account.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="account.updated_by" readonly></b-form-input>
                            <b-badge v-if="account.updated_by === account.id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="account.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="account.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="getAccount()">Refresh</b-button>
                <b-button variant="outline-danger" class="ml-auto" v-b-modal="'delete-account'">Delete</b-button>
                <b-modal id="delete-account" title="Delete Account" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                    <b-row>
                        <b-col class="text-center">
                            You're about to delete your account. This action can not be undone. Note that all of your resources will also be deleted, including tenants and all of their resources, including users.
                        </b-col>
                    </b-row>
                    <template #modal-footer>
                        <b-row class="w-100">
                            <b-col class="d-flex px-0">
                                <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-account')">Cancel</b-button>
                                <b-button variant="danger" class="ml-auto" v-on:click="deleteAccount()">Delete</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-modal>
            </b-card-body>
            <b-card-footer v-if="account?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ account.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const SUBTYPES = [
    { value: 'user', text: 'User' },
    { value: 'client', text: 'Client' }
];
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Account',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // ACCOUNT
            account: undefined,
            // SUBTYPES
            subtypes: SUBTYPES,
            // STATUSES
            statuses: STATUSES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.$store.state.account) {
                    this.getAccount();
                } else if (!this.account) {
                    this.resetAccount();
                }
            }
        },

        /**
         * ACCOUNT
         */
        async getAccount() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getAccount($id: ID!) {
                                getAccount(id: $id) {
                                    id
                                    label
                                    subtype
                                    status
                                    config {
                                        internal
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$store.state.account_id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const account = (await response.json()).data.getAccount;
                    // ADD REFRESH DATE
                    account.refreshed_at = new Date();
                    this.$store.commit('account', account);
                    this.resetAccount();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain account.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain account.', 'Account', 'danger');
            }
            this.loading = undefined;
        },

        resetAccount() {
            this.account = {
                id: this.$store.state.account.id,
                label: this.$store.state.account.label,
                subtype: this.$store.state.account.subtype,
                status: this.$store.state.account.status,
                config: {
                    internal: this.$store.state.account.config.internal
                },
                created_at: this.$store.state.account.created_at ? new Date(this.$store.state.account.created_at) : undefined,
                created_by: this.$store.state.account.created_by,
                updated_at: this.$store.state.account.updated_at ? new Date(this.$store.state.account.updated_at) : undefined,
                updated_by: this.$store.state.account.updated_by,
                expires_at: this.$store.state.account.expires_at ? new Date(this.$store.state.account.expires_at) : undefined,
                refreshed_at: this.$store.state.account.refreshed_at
            };
        },

        async deleteAccount() {
            this.loading = 'Deleting';
            this.$bvModal.hide('delete-account');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation deleteAccount($input: DeleteAccountInput!) {
                                deleteAccount(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.$store.state.account_id}"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    (await response.json()).data.deleteAccount;
                    this.$emit('alert', 'Your account has been deleted.', 'Account', 'success', 5000);
                    this.$emit('login', true); // LOGOUT
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to delete account.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to delete account.', 'Account', 'danger');
            }
            this.loading = undefined;
        }
    }

}
</script>
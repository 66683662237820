<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tokens.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Token</h3>
            </b-card-header>
            <b-card-body v-if="token">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="token.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="token.subtype" :options="subtypes" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="token.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="token">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="token.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="token.created_by">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="token.created_by" readonly></b-form-input>
                                <b-badge v-if="token.created_by === $store.state.account_id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="token.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="token.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="token.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="token.updated_by" readonly></b-form-input>
                                <b-badge v-if="token.updated_by === $store.state.account_id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="token.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="token.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="getToken()">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="token?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ token.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'oauth2:code', text: 'Authorization Code' },
    { value: 'oauth2:access', text: 'Access Token' },
    { value: 'oauth2:refresh', text: 'Refresh Token' },
    { value: 'oauth2:assertion', text: 'Assertion' },
    { value: 'oauth2:request', text: 'Authorization Request' },
    { value: 'oidc1:id', text: 'Identity Token' },
    { value: 'session', text: 'Session' },
    { value: 'online:consent', text: 'Consent' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Token',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // TOKEN
            token: undefined,
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.token) {
                this.getToken();
            }
        },

        /**
         * TOKEN
         */
        async getToken() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getToken($id: ID!) {
                                getToken(id: $id) {
                                    id
                                    subtype
                                    status
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.token = (await response.json()).data.getToken;
                    // SET DATES
                    this.token.created_at = this.token.created_at ? new Date(this.token.created_at) : undefined;
                    this.token.updated_at = this.token.updated_at ? new Date(this.token.updated_at) : undefined;
                    this.token.expires_at = this.token.expires_at ? new Date(this.token.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.token.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain token.', 'Token', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain token.', 'Token', 'danger');
            }
            this.loading = undefined;
        }
    }
}
</script>